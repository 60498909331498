* {
  margin: 0;
}

p {
  color: white;
}

/*SpectralSC Import*/
@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/*Blurb Dots*/
.blurb-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.blurb-dots li {
  position: relative;

  display: inline-block;

  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  cursor: pointer;
}
.blurb-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 30px;
  height: 30px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.blurb-dots li button:hover,
.blurb-dots li button:focus {
  outline: none;
}
.blurb-dots li button:hover:before,
.blurb-dots li button:focus:before {
  opacity: 1;
}
.blurb-dots li button:before {
  font-family: "slick";
  font-size: 12px;
  line-height: 30px;

  position: absolute;
  top: 0;
  left: 0;

  width: 30px;
  height: 30px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blurb-dots li.slick-active button:before {
  opacity: 0.75;
  color: white;
}
body {
  overflow-x: hidden !important;
}
